<template>
  <div class="farm_service_index">
    <a-row :gutter="30">
      <a-col v-if="$route.path.indexOf('detail') < 0" :span="5">
        <div class="top"></div>
        <div class="columns">
          <div class="item" v-for="item in _menus" :key="item.path">
            <router-link :to="item.path">{{ item.meta.title }}</router-link>
          </div>
        </div>
      </a-col>
      <a-col :span="$route.path.indexOf('detail') > -1 ? 24 : 19">
        <div v-if="$route.path.indexOf('detail') < 0" :span="5" class="toolbar">
          <div class="search">
            <input
              type="text"
              placeholder="请输入要搜索的内容"
              v-model="searchKeyword"
              name="keyword"
              value=""
            />
            <a-button type="primary" @click="search">搜索</a-button>
          </div>
        </div>
        <div class="list">
          <router-view :keyword="keyword"></router-view>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script type="text/ecmascript-6">
const columns = [
  { name: "农资", id: 1 },
  { name: "农服", id: 2 },
];
import { menus } from "@/router/routes";
export default {
  data() {
    return {
      columns,
      keyword: "",
      searchKeyword: "",
    };
  },
  components: {},
  computed: {
    _menus() {
      let m = this._.filter(menus, (item) => item.path == "/farm_service");
      return this._.filter(m[0].children, (item) => item.meta.menu);
    },
  },
  methods: {
    search() {
      this.keyword = this.searchKeyword;
    },
  },
};
</script>

<style scoped lang="scss">
.farm_service_index {
  padding: 30px 0;
  .top {
    height: 60px;
    background: url("~@/assets/images/farm_left_top.png") no-repeat left bottom
      #68cba5;
    background-size: 100% auto;
    border-bottom: 4px solid #008840;
  }
  .columns {
    .item {
      height: 50px;
      line-height: 50px;
      text-align: center;
      margin-top: 10px;
      font-size: 18px;
      letter-spacing: 10px;
      a {
        display: block;
        text-decoration: none;
        color: #333;
        background: #e5effc;
        &.router-link-exact-active {
          background-color: #008840;
          color: white;
        }
      }
    }
  }
  .toolbar {
    border-bottom: 2px solid #eee;
    height: 58px;
    overflow: hidden;
    .search {
      float: right;
      width: 30%;
    }
  }
}
</style>
